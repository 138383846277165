/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  
  $( document ).ready(function() {

    try{
      if($('#postShares').length){
        var sticky = new Waypoint.Sticky({
          element: $('#postShares')[0],
          offset: 150,
        });
        
      }
    }catch(e){
      console.log(e)
    }
    
    $( window ).resize();
    
    jQuery('.vdg-gallery-container').each(function(){
      jQuery(this).find('.vdg-gallery-main').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: '.vdg-gallery-thumbnails'
      });
      jQuery(this).find('.vdg-gallery-thumbnails').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        asNavFor: '.vdg-gallery-main',
        centerMode: true,
        focusOnSelect: true,
        dots: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 981,
            settings: {
              slidesToShow: 3
            }
          }
        ]
      });
    });
    
    
  });
  
  if($('#postShares').length){
    $( document ).scroll(function() {
  		var distance = window.pageYOffset - $('#scroll-limit').offset().top+150;
      if(distance > 0){
        $('#postShares').addClass('sticky-surpassed');
      }else{
        $('#postShares').removeClass('sticky-surpassed');
      }
  	});
  }
  
  $( window ).resize(function() {
      jQuery('#raise-to-first').each(function(){
        
      	var h = jQuery('.elementor>.elementor-section:first-of-type .vdg-featured-post .entry-summary').outerHeight();
      	jQuery('#raise-to-first .elementor-column-wrap').css('margin-top', -h+'px');
      });
      
      jQuery('.estilo-fullwidth .fullwidth_img').css( 'marginLeft', -( 
        parseInt(jQuery('.main-content > .post').css('margin-left'))  + parseInt(jQuery('.main').css('padding-left'))  + parseInt(jQuery('.wrap').css('margin-left')) ) );

      jQuery('.elementor>.elementor-section:first-of-type .vdg-featured-post .vdg-posts-image .bgimage, .fullbg-section').css( 'marginLeft', -( parseInt(jQuery('.wrap').css('margin-left'))+1+ parseInt(jQuery('.main').css('padding-left')) ) );

      jQuery('.estilo-fullwidth .fullwidth_img, .elementor>.elementor-section:first-of-type .vdg-featured-post .vdg-posts-image .bgimage, .fullbg-section').css('width', jQuery('body').width()+1 );
      jQuery('.estilo-fullwidth:not(.esconder-sidebar) .post>header .featured-image').css('width', jQuery('body').width()+1 );
      
      var adminbarh = 0;
      if(jQuery('#wpadminbar').height()){ adminbarh = parseInt(jQuery('#wpadminbar').height()); };
      jQuery('.video-title-container').css('margin-top', -(parseInt(jQuery('.video-title-container').height()) + adminbarh ) );
      
      console.log('test');
      
  });
  
  jQuery('.rll-youtube-player').on('click', function(){
  	jQuery('.video-title-container').addClass('video-played');
  });
  
  if( jQuery('.video-embed-container iframe').length ){
    if( jQuery('.video-embed-container iframe').attr('src').indexOf('vimeo') != -1 ){
      jQuery('.video-title-container').addClass('video-played');
    }
  }
  
  jQuery('html[lang=es-ES] .wspsc_add_cart_submit').attr('value','Añadir al Carrito');

})(jQuery); // Fully reference jQuery after this point.


  
  function toggleMenu(){
    if( jQuery('header.banner').hasClass('is-active')){
      jQuery('header.banner').removeClass('is-active');
      jQuery('header.banner .hamburger').removeClass('is-active');
    }else{
      jQuery('header.banner').addClass('is-active');
      jQuery('header.banner .hamburger').addClass('is-active');
    }
  }
  
  function toggleMenuSearchBar(){
    if( jQuery('header.banner').hasClass('show-search')){
      jQuery('header.banner').removeClass('show-search');
    }else{
      jQuery('header.banner').addClass('show-search');
      jQuery( "input.search-field" ).focus();
    }
  }
  
  function openZoomProduct(pid){
    jQuery(".producto").removeClass('is-active');
  	jQuery("#producto-"+pid).addClass('is-active');
  	
  	
          jQuery('#producto-'+pid+' .vdg-gallery-main').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
            asNavFor: '#producto-'+pid+' .vdg-gallery-thumbnails'
          });
          jQuery('#producto-'+pid+' .vdg-gallery-thumbnails').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '#producto-'+pid+' .vdg-gallery-main',
            centerMode: true,
            focusOnSelect: true,
            dots: true,
            arrows: false,
            responsive: [
              {
                breakpoint: 981,
                settings: {
                  slidesToShow: 3
                }
              }
            ]
          });
          jQuery('#producto-'+pid+' img.lazy-hidden').each(function(){
            jQuery(this).attr('src', jQuery(this).attr('data-src'));
          });
            
          
  	
  }
  
  function closeZoomProduct(){
  	jQuery(".producto").removeClass('is-active');
  }